.is-marginless {
  margin: 0;
}

.is-height-auto {
  height: auto !important;
}

.input.is-borderless {
  border: none;
}

.is-pointable {
  cursor: pointer;
}

.is-flex-grow {
  flex-grow: 1 !important;
}
