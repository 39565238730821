.estimate-table-header-horizontal {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

.estimates-table-estimates-heading {
  max-width: 9em;
}

.estimates-table-confidence-heading {
  max-width: 6em;
}


.estimates-table-small-column {
  max-width: 2em;

  span {
    margin-top: 0.5em;
    display: inline-block;
  }
}

.estimates-table-computed-column {
  max-width: 8em;
}

tr {
  transition: height 0.4s linear;
}
